<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <iframe
          src="http://www.jung-klaus.de/table.html"
          width="570"
          height="1400"
          border="0"
          frameborder="0"
        >
        </iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld"
};
</script>